import { ref, onMounted } from 'vue'
import ApexCharts from 'apexcharts'
import { dateRangeConfig } from '@/config/dashboard'

//  圖表hook
export const useChart = (options) => {
  let chart
  const chartRef = ref(null)

  //  圖表實體化
  const renderChart = () => {
    chart = new ApexCharts(chartRef.value, options)
    chart.render()
  }

  //  更新圖表
  const updateChart = (newOptions) => {
    chart.updateOptions(newOptions, true)
  }

  onMounted(() => {
    renderChart()
  })

  return {
    chartRef,
    updateChart,
  }
}

export const useDateSelect = (useRangeType) => {
  const selectOptions = ref(dateRangeConfig)
  const rangeType = ref('day')

  const currentOption = ref(selectOptions.value[0].value)

  const dateSelectHandler = (val, callback) => {
    currentOption.value = val

    if (useRangeType) {
      if (val !== '30') {
        rangeTypeHandler('week')
      } else {
        rangeTypeHandler('day')
      }
    }
  }

  const rangeTypeHandler = (val = 'day', callback) => {
    if (val !== rangeType.value) {
      rangeType.value = val
    }
  }

  return {
    selectOptions,
    currentOption,
    rangeType,
    dateSelectHandler,
    rangeTypeHandler,
  }
}
