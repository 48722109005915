<template>
  <div class="form-item-tooltip-lable" style="gap: 8px">
    <p class="text-normal">{{ label }}</p>
    <el-tooltip placement="right">
      <div slot="content" :style="`width: ${tooltipWidth}px`">
        <slot />
      </div>
      <span class="material-icons">help_outline</span>
    </el-tooltip>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FormItemTooltipLabel',
  props: {
    label: String,
    tooltipWidth: [String, Number],
  },
})
</script>

<style lang="postcss" scoped>
.form-item-tooltip-lable {
  @apply flex items-center !inline-flex;
}
</style>
