import { getCssVar } from "@/utils/helper"

//  檢視日期區間選單
export const dateRangeConfig = [
  {
    label: '檢視過往30天',
    value: '30',
  },
  {
    label: '檢視過往90天',
    value: '90',
  },
  {
    label: '檢視過往180天',
    value: '180',
  },
  {
    label: '檢視過往360天',
    value: '360',
  },
  {
    label: '檢視當月',
    value: 'month',
  },
  {
    label: '檢視當年',
    value: 'year',
  },
]

//  圖表工具配置
const toolbar = {
  tools: {
    download: true,
    selection: true,
    zoom: false,
    zoomin: true,
    zoomout: true,
    pan: true,
  },
}

//  橫向長條圖
export const barChartOptions = {
  chart: {
    type: 'bar',
    height: '450',
    width: '100%',
    toolbar,
  },
  dataLabels: {
    enabled: true,
    offsetX: 40,
    formatter: function (val) {
      return val + '人'
    },
    style: {
      colors: ['#636363'],
    },
  },
  legend: {
    show: false,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '15',
      dataLabels: {
        position: 'top',
      },
    },
  },
}

//  氣泡圖
export const bubbleChartOptions = {
  chart: {
    type: 'bubble',
    height: '450',
    width: '100%',
    toolbar,
  },
  colors: [
    getCssVar('chart-1'),
    getCssVar('chart-2'),
    getCssVar('chart-3'),
    getCssVar('chart-4'),
    getCssVar('chart-5'),
    getCssVar('chart-6'),
    getCssVar('chart-7'),
    getCssVar('chart-8'),
    getCssVar('chart-9'),
    getCssVar('chart-10'),
    getCssVar('chart-11'),
    getCssVar('chart-12'),
  ],
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: 'top',
    markers: {
      width: 12,
      height: 12,
      radius: 0,
      strokeWidth: 0,
    },
  },
  fill: {
    opacity: 0.8,
  },
  markers: {
    strokeWidth: 0,
  },
  tooltip: {
    z: {
      title: '人數',
    },
  },
}

//  長條圖
export const columnChartOptions = {
  chart: {
    type: 'bar',
    height: '330',
    width: '100%',
    toolbar,
  },
  dataLabels: {
    enabled: true,
    offsetX: 30,
    style: {
      colors: ['#000'],
    },
  },
  legend: {
    show: false,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        position: 'top',
      },
      columnWidth: '20px',
    },
  },
}

//  圓餅圖(甜甜圈)
export const donutChartOptions = {
  chart: {
    type: 'donut',
    height: '330',
    width: '100%',
    toolbar,
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: 'right',
    horizontalAlign: 'center',
    markers: {
      width: 12,
      height: 12,
      radius: 0,
      strokeWidth: 0,
    },
  },
}

//  折線圖
export const lineChartOptions = {
  chart: {
    type: 'line',
    height: '330',
    width: '100%',
    toolbar,
  },
  stroke: {
    width: 2.5,
    curve: 'straight',
  },
  markers: {
    size: 4,
    strokeWidth: 0,
    hover: {
      size: 4,
    },
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1],
  },
  legend: {
    position: 'top',
    markers: {
      width: 12,
      height: 12,
      radius: 0,
      strokeWidth: 0,
    },
  },
  tooltip: {
    x: {
      show: false,
    },
  },
}

//  混合圖表(長條圖+折線圖)
export const lineColumnChartOptions = {
  chart: {
    type: 'line',
    height: '330',
    width: '100%',
    toolbar,
  },
  stroke: {
    width: 2.5,
    curve: 'straight',
  },
  markers: {
    size: 4,
    strokeWidth: 0,
    hover: {
      size: 4,
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      columnWidth: '20',
    },
  },
  legend: {
    position: 'top',
    showForSingleSeries: true,
    markers: {
      width: 12,
      height: 12,
      radius: 0,
      strokeWidth: 0,
    },
  },
  tooltip: {
    x: {
      show: false,
    },
  },
}
