import { admin2 } from './instance'

//  主要獲取報表的key
export const GetAvailableKeys = async ({ shopId }) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/businessDataChart/availableKeys`,
  })
}

//  根據key獲取相對應圖表資料
export const GetBusinessDataChartData = async ({ shopId, key, parameters }) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/businessDataChart/${key}`,
    data: {
      parameters,
    },
  })
}
